import React, { Component } from 'react';
import Cover from 'react-video-cover';
import { isMobile } from 'react-device-detect';
import Media from 'react-media';

const bgContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
};

const position = {
  left: 'left center',
  right: '90% center'
}

class BackgroundVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      objectPosition: '',
      smallScreen: false,
      queryHasRenderedOnce: false
    };
  }

  componentDidUpdate() {
    if (this.state.queryHasRenderedOnce) {
      this.fetchVideoPositionStyle();
    }
  }

  doesBGNeedToSlideOver = () => {
    return (this.state.smallScreen || isMobile) && this.props.routePath !== '/';
  }

  fetchVideoPositionStyle = () => {
    if (this.doesBGNeedToSlideOver()) {
      if (this.state.objectPosition !== position.left) {
        this.setState({ objectPosition: position.left });
      }
    } else if (this.state.objectPosition !== position.right) {
      this.setState({ objectPosition: position.right });
    }
  }

  handleQueryStateChange = async match => {
    await this.setState({
      queryHasRenderedOnce: true,
      smallScreen: match
    });
    this.fetchVideoPositionStyle();
  }

  render() {
    const videoStyle = {
      objectPosition: this.state.objectPosition,
      transition: '500ms all ease'
    };
    const videoOptions = {
      src: this.props.video,
      playsInline: true,
      autoPlay: true,
      muted: true,
      loop: true,
    };
    return (
      <div>
        <Media
          query="(max-width: 891px)"
          onChange={this.handleQueryStateChange}
        />
        <div style={bgContainerStyle}>
          <Cover
            style={videoStyle}
            videoOptions={videoOptions}
            remeasureOnWindowResize
          />
        </div>
      </div>
    );
  }
}

export default BackgroundVideo;
