import React, { Component } from 'react';
import ImagesLoaded from 'react-images-loaded';
import ImageMapper from 'react-img-mapper';

import ariName from '../images/QBOMB_GROUP_ARI.png';
import izzyName from '../images/QBOMB_GROUP_IZZY.png';
import jakeName from '../images/QBOMB_GROUP_JAKE.png';
import jordieName from '../images/QBOMB_GROUP_JORDIE.png';
import remName from '../images/QBOMB_GROUP_REM.png';
import samName from '../images/QBOMB_GROUP_SAM.png';
import bandmateImg from '../images/qbomb-group.png';
import '../styles/about.scss';

const imageMapBand = {
  name: "bandlist",
  areas: [
    {
      id: 'jake',
      name: "1",
      title: "Jake Easy",
      shape: "poly",
      fillColor: ["rgba(18, 148, 129, 0.35)"],
      image: jakeName,
      coords: [0, 0, 0, 1026, 405, 1026, 320, 0],
      polygon: [
        [0, 0], 
        [0, 1026],
        [405, 1026],
        [320, 0]
      ],
      social: "https://twitter.com/jakestrick"
    }, {
      id: 'ari',
      name: "2",
      title: "Ari Ari",
      image: ariName,
      shape: "poly",
      fillColor: ["rgba(59, 90, 228, 0.35)"],
      coords: [320, 0, 405, 1026, 643, 1026, 755, 0],
      polygon: [
        [320, 0],
        [405, 1026],
        [643, 1026],
        [755, 0]
      ],
      social: "https://instagram.com/castletoons"
    }, {
      id: 'jordie',
      name: "3",
      title: "Jordie LaLa",
      image: jordieName,
      shape: "poly",
      fillColor: ["rgba(126, 0, 149, 0.35)"],
      coords: [755, 0, 643, 1026, 1000, 1026, 1000, 0],
      polygon: [
        [755, 0],
        [643, 1026],
        [1000, 1026],
        [1000, 0]
      ],
    social: "https://instagram.com/jordie_lala"
    }, {
      id: 'izzy',
      name: "4",
      title: "Izzy Deluxe",
      image: izzyName,
      shape: "poly",
      fillColor: ["rgba(149, 0, 68, 0.35)"],
      coords: [1000, 0, 1000, 1026, 1276, 1026, 1396, 0],
      polygon: [
        [1000, 0],
        [1000, 1026],
        [1276, 1026],
        [1396, 0]
      ],
      social: "https://twitter.com/IZZY_DELUXE"
    }, {
      id: 'rem',
      name: "5",
      title: "Rem",
      image: remName,
      shape: "poly",
      fillColor: ["rgba(0, 74, 112, 0.35)"],
      coords: [1396, 0, 1276, 1026, 1763, 1026, 1689, 0],
      polygon: [
        [1396, 0],
        [1276, 1026],
        [1763, 1026],
        [1689, 0]
      ],
      social: "https://instagram.com/remropp"
    }, {
      id: 'sam',
      name: "6",
      title: "Sam Gamma",
      image: samName,
      shape: "poly",
      fillColor: ["rgba(84, 10, 157, 0.35)"],
      coords: [1689, 0, 1763, 1026, 2000, 1026, 2000, 0],
      polygon: [
        [1689, 0],
        [1763, 1026],
        [2000, 1026],
        [2000, 0]
      ],
      social: "https://instagram.com/samgamma"
    }
  ]
};

class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load: false,
      imageWidth: 0,
    };
  }

  _handleImageLoad = () => {
    this.setState({
      load: true
    });
  }

  loadBandmateList() {
    if (this.state.load) {
      return 'load-bandmate-list'
    }
  }

  imageMapAreaClick(area, event) {
    window.open(area.social, '_blank');
  }

  enterImageMapArea(area) {
    this.setState({ hoveredArea: area, nameImage: area.image });
  }

  leaveImageMapArea(area) {
    this.setState({ hoveredArea: null, nameImage: null });
  }

  getTipPosition(area) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }

  onWindowResize = () => {
    const wrapper = document.querySelector(".about").clientWidth;
    this.setState({ imageWidth: wrapper });
  }

  componentDidMount() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  render() {
    return (
      <main className="page">
        <section className="about">
          <ImagesLoaded
            className={`bandmates-list ${this.loadBandmateList()}`}
            onFail={ this._handleImageLoad }
            done={ this._handleImageLoad }
          >
            <ImageMapper 
              src={ bandmateImg }
              map={ imageMapBand }
              responsive="true"
              width={ this.state.imageWidth }
              imgWidth={ this.state.imageWidth }
              parentWidth={ this.state.imageWidth }
              onClick={ (area, _id, event) => this.imageMapAreaClick(area, event) }
              onMouseEnter={ (area, _id, event) => this.enterImageMapArea(area, event) }
              onMouseLeave={ (area, _id, event) => this.leaveImageMapArea(area, event) }
            />
            {
              this.state.hoveredArea &&
              <span 
                className="bandmate-name"
                style={{ ...this.getTipPosition(this.state.hoveredArea)}}>
              </span>
            }
          </ImagesLoaded>
          <h1>
            <span>Six</span> punk pals making<br />
            music in <span>Los Angeles</span>
          </h1>
        </section>
      </main>
    );
  }
}

export default About;
