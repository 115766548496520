import React, { Component } from "react";

class PromoSection extends Component {
  render() {
    const sectionBGColor = `section-bg-${
      this.props.color ? this.props.color : "black"
    }`;
    const title = this.props.title ? <h1>{this.props.title}</h1> : "";
    const subtitle = this.props.subtitle ? (
      <strong>{this.props.subtitle}</strong>
    ) : (
      ""
    );
    return (
      <section
        className={`section ${sectionBGColor}${
          this.props.padding ? " section-padding" : ""
        }${this.props.header ? " section-header" : ""}${
          this.props.footer ? " section-footer" : ""
        }`}
      >
        {title}
        {subtitle}
        <div className="section-inner">{this.props.children}</div>
      </section>
    );
  }
}

export default PromoSection;
