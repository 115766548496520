import React, { Component } from "react";
import Preload from "react-preload";
import { ScaleLoader } from "react-spinners";
import { isMobile, isTablet } from "react-device-detect";
import MainContent from "./MainContent";

import qbLogo from "../images/logo.png";
import bgMobileVideo from "../bg/bg-video-mobile.mp4";
import bgDesktopVideo from "../bg/bg-video-desktop.mp4";

const bgVideo = isMobile || isTablet ? bgMobileVideo : bgDesktopVideo;

class MainApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loaded: false
    };
  }

  componentDidMount() {
    document.body.classList.add("main-app");
  }

  componentWillUnmount() {
    document.body.classList.remove("main-app");
  }

  _handleFileLoadError = failedFiles => {
    this.setState({
      error: true
    });
  };

  _handleImageLoadSuccess = () => {
    this.setState({
      loaded: true
    });
  };

  render() {
    const fileList = [`${qbLogo}`, `${bgVideo}`];
    return (
      <Preload
        images={fileList}
        onError={this._handleImageLoadError}
        onSuccess={this._handleImageLoadSuccess}
        loadingIndicator={
          <div className="loading">
            <ScaleLoader
              sizeUnit={"px"}
              height={35}
              width={4}
              margin={"2px"}
              radius={2}
              color={"#ffffff"}
              loading={this.state.loading}
            />
          </div>
        }
        mountChildren
        resolveOnError
      >
        <MainContent logo={qbLogo} video={bgVideo} />
      </Preload>
    );
  }
}

export default MainApp;
