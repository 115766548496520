import React, { Component } from 'react';
import ImagesLoaded from 'react-images-loaded';

import hyperpunkCover from '../images/hyperpunk-lowres.jpg';
import frontpageText from '../images/frontpage-text.png';
import '../styles/home.scss'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load: false
    };
  }

  _handleImageLoad = () => {
    this.setState({
      load: true
    });
  }

  loadAlbum() {
    if (this.state.load) {
      return 'load-album'
    }
  }

  render() {
    return (
      <main className="page">
        <section className="home">
          <ImagesLoaded
            className={`album ${this.loadAlbum()}`}
            onFail={ this._handleImageLoad }
            done={ this._handleImageLoad }
          >
            <a
              href="https://ada.lnk.to/hyperpunk/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="album-cover" src={ hyperpunkCover } alt="Hyperpunk by QBomb" width="500" />
              <img className="album-text" src={ frontpageText } alt="Debut Album Streaming Now!" />
            </a>
          </ImagesLoaded>
        </section>
      </main>
    );
  }
}

export default Home;
