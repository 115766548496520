import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ScrollArea from 'react-scrollbar';
import moment from 'moment';
import ImagesLoaded from 'react-images-loaded';

import { fetchShows } from '../actions';
import '../styles/shows.scss';

class Shows extends Component {
  constructor(props) {
    super(props)
    this.state = {
      load: false,
      image: {
        opened: false,
        url: null
      }
    };
  }

  componentDidMount() {
    this.props.fetchShows()
  }

  _handleImageLoad = () => {
    this.setState({
      load: true
    });
  }

  renderUpcomingShowsList(shows) {
    if (!shows.length) {
      return (
        <p>
          No shows are scheduled at this time.<br />
          Check back later!
        </p>
      )
    }
    return this.renderShowsList(shows)
  }

  renderShowsList(shows) {
    return _.map(shows, show => {
      const date = moment(show.date, 'M/D/YYYY');
      const hasDatePassed = date.isBefore(moment());
      return (
        <article key={ show.date } className={`show ${hasDatePassed ? 'old-date' : 'upcoming-date'}`}>
          <figure className="show-poster">
            <img onClick={ ()=> this.expandImage(show) } src={show.poster} alt={ `${show.name} poster` } />
          </figure>
          <div className="show-info">
            <time className="time" dateTime={ date.format('YYYY-MM-DD') }>{ show.date }</time>
            <h2 className="venue">{ show.name }</h2>
            <div className="location">{ show.location }</div>
            <div className="text">{ show.text }</div>
            { this.isTicketedOrFree(show) }
          </div>
        </article>
      );
    });
  }

  renderShowsBlock() {
    const { shows } = this.props;
    let upcomingShows = [];
    let pastShows = [];
    for (const show of shows) {
      const date = moment(show.date, 'M/D/YYYY');
      const hasDatePassed = date.isBefore(moment());
      if (hasDatePassed) {
        pastShows.push(show)
      } else {
        upcomingShows.push(show)
      }
    }
    return (
      <div className="all-shows">
        <div className="upcoming-shows">
          <h3>Upcoming Shows</h3>
          {this.renderUpcomingShowsList(upcomingShows)}
        </div>
        <div className="past-shows">
          <h3>Past Shows</h3>
          {this.renderShowsList(pastShows)}
        </div>
      </div>
    );
  }

  expandImage(image) {
    this.setState({
      image: {
        opened: true,
        url: image.poster
      }
    });
  }

  closeImage() {
    this.setState({
      image: {
        opened: false
      }
    });
  }

  isTicketedOrFree(show) {
    if (show.tickets) {
      return (
        <a href={ show.ticketLink } target="_blank" rel="noopener noreferrer">Buy Tickets</a>
      );
    }
    return (
      <span>Free Show!</span>
    );
  }

  loadShows() {
    if (this.state.load) {
      return 'load-show-list'
    }
  }

  renderShowImageModal() {
    const mainStyle = {
       position: 'absolute',
       width: '30px',
       height: '30px',
       right: '1.5rem',
       top: '-0.5rem'
    };
    const spanLines = {
      position: 'absolute',
      top: '1px',
      right: '17px'
    };
    const firstLine = {
      position: 'absolute',
      width: '6px',
      height: '30px',
      transform: 'rotate(45deg)'
    };
    const secondLine = {
      position: 'absolute',
      width: '6px',
      height: '30px',
      transform: 'rotate(-45deg)',
    };
    const buttonStyle = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      margin: '0px',
      padding: '0px',
      border: 'none',
      fontSize: '0px',
      background: 'transparent',
      cursor: 'pointer',
      outline: 0
    }
    if (this.state.image.opened) {
      return (
        <div className="image-modal">
          <div className="image-wrap">
            <div className="bm-cross-button" style={mainStyle}>
              <span style={spanLines}>
                <span className="bm-cross" style={firstLine}></span>
                <span className="bm-cross" style={secondLine}></span>
              </span>
              <button tabIndex="0" style={buttonStyle} onClick={ () => this.closeImage() }>Close Menu</button>
            </div>
            <img src={this.state.image.url} alt="poster" />
          </div>
        </div>
      );
    }
  }

  render() {
    const scrollbarContainer = {
      background: 'transparent'
    };
    const scrollbarInner =  {
      borderRadius: '5px',
      background: '#fff',
      opacity: 1
    }
    if (!this.props.shows) {
      return null;
    }
    return (
      <main className="page">
        <section className="shows">
          <ScrollArea
            className="area"
            contentClassName="content"
            verticalScrollbarStyle={scrollbarInner}
            verticalContainerStyle={scrollbarContainer}
            horizontalScrollbarStyle={scrollbarContainer}
            horizontalContainerStyle={scrollbarContainer}
            smoothScrolling= {true}
            minScrollSize={40}
          >
            <ImagesLoaded
              className={`show-list ${this.loadShows()}`}
              onFail={ this._handleImageLoad }
              done={ this._handleImageLoad }
            >
              { this.renderShowsBlock() }
            </ImagesLoaded>
          </ScrollArea>
        </section>
        {this.renderShowImageModal()}
      </main>
    );
  }
}

function mapStateToProps(state) {
  return { shows: state.shows };
}

export default connect(mapStateToProps, { fetchShows })(Shows);
