import React, { Component } from 'react';
import {
  getFormData,
  validateHuman,
  validEmail,
  disableAllButtons
} from '../utils/formSubmission'

import '../styles/contact.scss';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = getFormData(form);

    if (validateHuman(data.honeypot)) {
      return false;
    }

    if( data.email && !validEmail(data.email) ) {
      var invalidEmail = form.querySelector(".email-invalid");
      if (invalidEmail) {
        invalidEmail.style.display = "block";
        return false;
      }
    } else {
      disableAllButtons(form);
      var url = form.action;
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url);
      // xhr.withCredentials = true;
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.onreadystatechange = function() {
        var formElements = form.querySelector(".form-elements")
        if (formElements) {
          formElements.style.display = "none"; // hide form
        }
        var thankYouMessage = form.querySelector(".thankyou_message");
        if (thankYouMessage) {
          thankYouMessage.style.display = "block";
        }
        return;
      };
      // url encode form data for sending as post data
      var encoded = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
      }).join('&');
      xhr.send(encoded);
    }
  }

  render() {
    /*var forms = document.querySelectorAll("form.gform");
    for (var i = 0; i < forms.length; i++) {
      forms[i].addEventListener("submit", handleFormSubmit, false);
    }*/
    return (
      <main className="page">
        <form
          className="gform contact-form"
          method="POST"
          action="https://script.google.com/a/dapshow.com/macros/s/AKfycbw6gq9COrqx82YYUltuNv7Y1vsjWSFBJ9tZIP35/exec"
          onSubmit={ this.handleFormSubmit }
        >
          <div className="form-elements">
            <fieldset className="contact-form-group">
              <label
                htmlFor="name"
                className="contact-form-label"
              >
                Name
              </label>
              <input
                id="name"
                name="name"
                className="contact-form-input"
              />
            </fieldset>
            <fieldset className="contact-form-group">
              <label
                htmlFor="email"
                className="contact-form-label"
              >
                Email
              </label>
              <input
                id="email"
                name="email"
                className="contact-form-input"
              />
            </fieldset>
            <fieldset className="contact-form-group">
              <label
                htmlFor="message"
                className="contact-form-label"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="contact-form-input"
                rows="6"
              ></textarea>
            </fieldset>
            <button type="submit">Send</button>
          </div>
          <div className="thankyou_message">
            <h2>Thanks for reaching out!</h2>
            <p>We'll be in touch with you soon.</p>
          </div>
        </form>
      </main>
    );
  }
}

export default Contact;
