export function validEmail(email) {
  var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
}

export function validateHuman(honeypot) {
  if (honeypot) {  //if hidden form filled up
    return true;
  }
}

// get all data in form and return object
export function getFormData(form) {
  var elements = form.elements;

  var fields = Object.keys(elements).filter(function(k) {
    return (elements[k].name !== "honeypot");
  }).map(function(k) {
    if (elements[k].name !== undefined) {
      return elements[k].name;
    // special case for Edge's html collection
    } else if (elements[k].length > 0) {
      return elements[k].item(0).name;
    }
    return undefined;
  }).filter(function(item, pos, self) {
    return self.indexOf(item) === pos && item;
  });

  var formData = {};
  fields.forEach(function(name){
    var element = elements[name];

    // singular form elements just have one value
    formData[name] = element.value;

    // when our element has multiple items, get their values
    if (element.length) {
      var data = [];
      for (var i = 0; i < element.length; i++) {
        var item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  // add form-specific values into the data
  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
  formData.formGoogleSendEmail = form.dataset.email || ""; // no email by default

  return formData;
}

export function disableAllButtons(form) {
  var buttons = form.querySelectorAll("button");
  for (var i = 0; i < buttons.length; i++) {
    buttons[i].disabled = true;
  }
}
