import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preload from "react-preload";
import { ScaleLoader } from "react-spinners";
import Swiper from "react-id-swiper";
import { saveAs } from "file-saver";

import PromoSection from "./PromoSection";
//pull in press api
import { fetchPressPage } from "../actions";
//import logo images
import qbLogo from "../images/logo-pink.png";
import qbQ from "../images/logo-q-pink.png";
//import social icons
import fbIcon from "../images/social-fb-pk.png";
import twIcon from "../images/social-tw-pk.png";
import igIcon from "../images/social-ig-pk.png";
import ytIcon from "../images/social-yt-pk.png";
import spIcon from "../images/social-sp-pk.png";

import "swiper/css/swiper.min.css";
import "../styles/presskit.scss";

class PressKitApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loaded: false
    };
  }

  componentDidMount() {
    document.body.classList.add("presskit");
    this.props.fetchPressPage();
  }

  componentWillUnmount() {
    document.body.classList.remove("presskit");
  }

  _handleFileLoadError = failedFiles => {
    this.setState({
      error: true
    });
  };

  _handleImageLoadSuccess = () => {
    this.setState({
      loaded: true
    });
  };

  openImage = imgPath => {
    const fileName = imgPath.split("/").pop();
    saveAs(imgPath, fileName);
  };

  renderSlides = photos => {
    if (photos.length) {
      return _.map(photos, photo => {
        return (
          <div
            onClick={() => {
              this.openImage(photo.highRes);
            }}
            key={photo.slider}
            style={{ backgroundImage: `url(${photo.slider})` }}
          />
        );
      });
    }
    return "";
  };

  renderVideos = videos => {
    const videoUrl = `https://www.youtube.com/embed/`;
    if (videos.length) {
      return _.map(videos, (video, key) => {
        return (
          <PromoSection
            title={video.videoTitle}
            subtitle={video.videoSubtitle}
            padding
            color={ (key % 2) === 1 ? "alt" : "" }
            key={video.videoTitle}
          >
            <div className="section-video-embed">
              <div className="section-video-container">
                <iframe
                  title="Official Music Video"
                  className="section-video"
                  src={`${videoUrl}${video.youtubeVideoId}`}
                  frameBorder="0"
                />
              </div>
            </div>
          </PromoSection>
        )
      });
    }
    return "";
  }

  render() {
    if (this.props.press) {
      const fileList = [
        `${qbLogo}`,
        `${qbQ}`,
        `${this.props.press.albumImage}`
      ];
      for (let p = 0; p < this.props.press.photos.length; p++) {
        fileList.push(this.props.press.photos[p].slider);
      }
      const swiperParams = {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      };

      return (
        <Preload
          images={fileList}
          onError={this._handleImageLoadError}
          onSuccess={this._handleImageLoadSuccess}
          loadingIndicator={
            <div className="loading">
              <ScaleLoader
                sizeUnit={"px"}
                height={35}
                width={4}
                margin={"2px"}
                radius={2}
                color={"#ff166d"}
                loading={this.state.loading}
              />
            </div>
          }
          mountChildren
          resolveOnError
        >
          <>
            <PromoSection padding header>
              <Link className="section-return-home" to="/">
                Home
              </Link>
              <Link to="/" className="section-logo">
                <img src={qbLogo} alt="Qbomb logo" />
              </Link>
              <ul className="section-social-links">
                <li className="section-social-link">
                  <a
                    className="social-icon"
                    href="https://www.instagram.com/qbombband"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={igIcon} alt="instagram icon" />
                  </a>
                </li>
                <li className="section-social-link">
                  <a
                    className="social-icon"
                    href="https://www.facebook.com/qbombmusic"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={fbIcon} alt="facebook icon" />
                  </a>
                </li>
                <li className="section-social-link">
                  <a
                    className="social-icon"
                    href="https://twitter.com/qbombband"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={twIcon} alt="twitter icon" />
                  </a>
                </li>
                <li className="section-social-link">
                  <a
                    className="social-icon"
                    href="https://www.youtube.com/channel/UCIAY8vXHBA1s1h6sjAoZaUQ"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={ytIcon} alt="youtube icon" />
                  </a>
                </li>
                <li className="section-social-link">
                  <a
                    className="social-icon"
                    href="https://open.spotify.com/artist/0IXhfnD7calHs6R5vilU62"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={spIcon} alt="spotify icon" />
                  </a>
                </li>
              </ul>
            </PromoSection>
            <PromoSection color="alt">
              <div className="section-grid b-1050">
                <div className="section-grid-item section-gallery">
                  <Swiper {...swiperParams}>
                    {this.renderSlides(this.props.press.photos)}
                  </Swiper>
                </div>
                <div className="section-grid-item section-about section-padding">
                  <h4>{this.props.press.bioTitle}</h4>
                  <p className="section-light-font">
                    {this.props.press.bioText}
                  </p>
                  <div className="section-links section-stretch">
                    <div className="section-grid section-management spaced">
                      <div className="section-grid-item half">
                        <strong className="section-grid-title section-dark-alt-text">
                          Management
                        </strong>
                        <a
                          className="section-contact"
                          href={`mailto:${this.props.press.managerEmail}`}
                          target="mail"
                        >
                          {this.props.press.managerName}
                        </a>
                      </div>
                      <div className="section-grid-item half">
                        <strong className="section-grid-title section-dark-alt-text">
                          Band
                        </strong>
                        <a
                          className="section-contact"
                          href="mailto:qbombband@gmail.com"
                          target="mail"
                        >
                          Email us
                        </a>
                      </div>
                    </div>
                    <div className="section-download">
                      <a
                        href="/zip/QBOMB_PRESS_KIT.zip"
                        rel="noopener noreferrer"
                        className="section-download-title"
                      >
                        Download
                        <br />
                        <span className="larger">Press Kit</span>
                      </a>
                      <div className="section-download-includes section-dark-alt-text section-text-uppercase section-light-font">
                        Includes logo, bio, high-res photos, and album art
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PromoSection>
            {this.renderVideos(this.props.press.videos)}
            <PromoSection
              color="alt"
              padding
              title={this.props.press.musicTitle}
            >
              <div className="section-grid b-800">
                <div className="section-grid-item large">
                  <a
                    href={this.props.press.albumImageLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Qbomb Bandcamp"
                  >
                    <img src={this.props.press.albumImage} alt="album cover" />
                  </a>
                </div>
                <div className="section-grid-item small">
                  <iframe
                    src={this.props.press.spotifyPlaylistLink}
                    title="Spotify playlist"
                    className="section-playlist"
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                  />
                </div>
              </div>
            </PromoSection>
            <PromoSection padding footer>
              <Link to="/" className="section-small-logo">
                <img src={qbQ} alt="q logo" />
              </Link>
              <div className="section-dev">
                <a
                  className="section-dev-link"
                  href="https://webdeveloperdude.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4 className="section-dev-title">Web Dev</h4>
                  <span className="section-dev-credit section-alt-text">
                    Justin King
                  </span>
                </a>
              </div>
            </PromoSection>
          </>
        </Preload>
      );
    }

    return "";
  }
}

function mapStateToProps(state) {
  return { press: state.press };
}

export default connect(mapStateToProps, { fetchPressPage })(PressKitApp);
