import { FETCH_PRESS } from "../actions/types";

export default function(state = null, action) {
  switch (action.type) {
    case FETCH_PRESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
