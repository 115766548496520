import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MainApp from "./MainApp";
import PressKitApp from "./PressKitApp";

import "../styles/base.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      font: false
    };
  }

  componentDidMount() {
    import("webfontloader").then(WebFontLoader => {
      WebFontLoader.load({
        active: () => {
          this.setState({
            font: true
          });
        },
        custom: {
          families: ["cipitilloregular", "punkkidregular"]
        }
      });
    });
  }

  fetchData = () => {
    if (this.props.location.pathname === "/presskit") {
      return <PressKitApp />;
    }
    return <MainApp />;
  };

  render() {
    return this.fetchData();
  }
}

export default withRouter(App);
