import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Media from "react-media";
import { slide as Menu } from "react-burger-menu";
import "../styles/sidebar.scss";

import fbIcon from "../images/social-fb.png";
import twIcon from "../images/social-tw.png";
import igIcon from "../images/social-ig.png";
import ytIcon from "../images/social-yt.png";
import spIcon from "../images/social-sp.png";
import dcIcon from "../images/social-dc.png";

class Sidebar extends Component {
  renderStandardMenu = () => {
    return [
      <a
        key="music"
        href="https://ada.lnk.to/hyperpunk/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Music
      </a>,
      <NavLink key="shows" activeClassName="current-page" to="/shows">
        Shows
      </NavLink>,
      <a key="merch" href="https://shop.qbomb.band">
        Merch
      </a>,
      <NavLink key="about" activeClassName="current-page" to="/band">
        Band
      </NavLink>,
      <NavLink key="contact" activeClassName="current-page" to="/contact">
        Contact
      </NavLink>,
      <NavLink key="presskit" to="/presskit">
        Press Kit
      </NavLink>,
      <ul key="socialLinks" className="social-links">
        <li className="social-link">
          <a
            className="social-icon"
            href="https://www.facebook.com/qbombmusic"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fbIcon} alt="facebook icon" />
          </a>
        </li>
        <li className="social-link">
          <a
            className="social-icon"
            href="https://twitter.com/qbombband"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twIcon} alt="twitter icon" />
          </a>
        </li>
        <li className="social-link">
          <a
            className="social-icon"
            href="https://www.instagram.com/qbombband"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={igIcon} alt="instagram icon" />
          </a>
        </li>
        <li className="social-link">
          <a
            className="social-icon"
            href="https://www.youtube.com/channel/UCIAY8vXHBA1s1h6sjAoZaUQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ytIcon} alt="youtube icon" />
          </a>
        </li>
        <li className="social-link">
          <a
            className="social-icon"
            href="https://open.spotify.com/artist/0IXhfnD7calHs6R5vilU62"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={spIcon} alt="spotify icon" />
          </a>
        </li>
        <li className="social-link">
          <a
            className="social-icon"
            href="https://discord.com/invite/GZ8tskdBN8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={dcIcon} alt="discord icon" />
          </a>
        </li>
      </ul>,
    ];
  };

  renderDesktopContainer = () => {
    return <nav className="sidebar-group">{this.renderStandardMenu()}</nav>;
  };

  renderMobileMenuContainer = () => {
    return (
      <Menu
        menuClassName={"sidebar-group"}
        width={"225px"}
        isOpen={false}
        right
      >
        {this.renderStandardMenu()}
      </Menu>
    );
  };

  render() {
    return (
      <section className="qb-sidebar">
        <Media query="(max-width: 891px)">
          {(matches) =>
            matches
              ? this.renderMobileMenuContainer()
              : this.renderDesktopContainer()
          }
        </Media>
      </section>
    );
  }
}

export default Sidebar;
