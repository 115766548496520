import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import Shows from "./Shows";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";

import Header from "./Header";
import Sidebar from "./Sidebar";
import BackgroundVideo from "./BackgroundVideo";

class MainContent extends Component {
  render() {
    return (
      <div className="app">
        <Header logo={this.props.logo} />
        <section className="site-container">
          <Sidebar />
          <Switch>
            <Route exact path="/shows" component={ Shows } />
            <Route exact path="/band" component={ About } />
            <Route exact path="/contact" component={ Contact } />
            <Route exact path="/" component={ Home } />
          </Switch>
        </section>
        <BackgroundVideo
          video={this.props.video}
          routePath={this.props.location.pathname}
        />
      </div>
    );
  }
}

export default withRouter(MainContent);
