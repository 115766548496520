import axios from "axios";
import { FETCH_SHOWS, FETCH_PRESS } from "./types";

export const fetchShows = () => async dispatch => {
  const res = await axios.get("/shows.json");

  dispatch({ type: FETCH_SHOWS, payload: res.data });
};

export const fetchPressPage = () => async dispatch => {
  const res = await axios.get("/press.json");

  dispatch({ type: FETCH_PRESS, payload: res.data });
};
