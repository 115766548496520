import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import '../styles/header.scss';

class Header extends Component {
  render() {
    return (
      <header className="qb-header">
        <div className="logo">
          <NavLink to="/"><img src={this.props.logo} alt="Qbomb text" /></NavLink>
        </div>
      </header>
    );
  }
}

export default Header;
